@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: white;
  color: black;
}

.container {
  max-width: 500px;
  margin: 30px auto;
  overflow: auto;
  min-height: 300px;
  border: 5px solid #c84343;
  padding: 30px;
  border-radius: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

h1
{
  font-family: 'Poppins', sans-serif;
  position: relative;
  bottom: 50px;
  left: 100px;
}

span
{
  font-size: 20px;
  font-weight: normal;
}
.header-first
{
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}
.header-second
{
  font-family: 'Coolvetica', sans-serif;
  font-weight: bolder;
  text-transform: lowercase;
}

input
{
  color: black;
  background: white;
  float: right;
  border-style: none;
  border-bottom: 2.5px solid black;
  width: 50%;
}

.URLs
{
  width: 50%;
}

.download
{
  text-align: center;
  margin-top: 10px;
}

.download button
{
  background: #c84343;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  padding: 15px 40px;
  margin: 15px;
  margin-top:17px;
  margin-bottom: 1px;
  border-radius: 24px;
  text-transform: uppercase;
}

#logo
{
  text-align: center;
  width: 350px;
  margin: 10px;
  padding: 0;
  position: relative;
  left: 30px;
}

h1 a
{
  text-decoration: none;
  color: black;
}

.result
{
  margin:0;
}